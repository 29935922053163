import React from 'react'
import './styles.sass'

export default ()=>{
  return (
  <div class="LoaderBalls">
    <div class="LoaderBalls__item"></div>
    <div class="LoaderBalls__item"></div>
    <div class="LoaderBalls__item"></div>
  </div>
  )
}